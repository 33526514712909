import React, { useEffect, useState } from "react";
import axios from "axios";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FloatingLabel,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Icons, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "../components/icons";
import LoadingScreen from "../components/loadingScreen";

// import QrReader from "react-qr-reader";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";

const myStyle = {
  sizeScan: {
    width: '350px',
 }
}
 

function AppScan() {
  const navigate = useNavigate();
  const [Cameraselected, setCameraSelected] = useState("environment");
  const [isProcessing, setIsProcessing] = useState(false);
  const [startScan, setStartScan] = useState(true);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");

  const refreshPage = () => { 
    window.location.reload(); 
  }

  const _doAbsenNow = (scanData, body) => {
    axios({
      method: "post",
      url: scanData,
      data: body,
    })
      .then(function (res) {
        switch (res.data.Data) {

          case "jabatan_tidak_terdaftar":
            refreshPage();
            // navigate("/ScanQr", { replace: true });
            toast.warning("Message : Jabatan Anda Belum diizinkan system.", {
              toastId: "custom-id-yes",
            });
          break;

          case "belum_masuk_waktu_checkin":
            refreshPage();
            // navigate("/ScanQr", { replace: true });
            toast.warning("Message : Anda belum waktunya check-in. Silahkan tunggu!", {
              toastId: "custom-id-yes",
            });
          break;

          case "iduser_absensi_kosong":
            refreshPage();
            // navigate("/ScanQr", { replace: true });
            toast.warning("Message : Session idUser Hilang. Silahkan ulangi login!", {
              toastId: "custom-id-yes",
            });
          break;
            default:
              var idabsesi = res.data.Data["IDABSENSI"];
              var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
              sessionStorage.setItem("IDABSENSI", idabsesi);
              sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
              navigate("/Dashboard", { replace: true });
              break;
          }
         
    
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data`, scanData);
    if (scanData != null && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
      var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
      switch (STATUS_ABSENSI) {
        case "CheckIn":
          NotificationManager.info("Info message");
          // setTimeout(() => {
            // for checkout
            var scanData = scanData+"/checkOut";
            var body = {
              idabsensi: sessionStorage.getItem("IDABSENSI", true),
              idjabatan: sessionStorage.getItem("IDJABATAN", true),
            };
            try {
              _doAbsenNow(scanData, body);
            } catch (error) {
              alert("Message : "+error)
            }
            // navigate("/Dashboard", { replace: true });
          // }, 3000);
          break;
        case "Completed":
          console.log("Anda Sudah Logout Hari Ini");
          break;
        default:
          // for check in
          var scanData = scanData+"/checkIn";
          var body = {
            iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
            idjabatan: sessionStorage.getItem("IDJABATAN", true),
          };
          try {
            _doAbsenNow(scanData, body);
          } catch (error) {
            alert("Message : "+error)
          }
          break;
      }
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const AbsenLogin = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    switch (STATUS_ABSENSI) {
      case "CheckIn":
        console.log("Anda Sudah Absen Hari Ini");
        navigate("/Dashboard", { replace: true });
        break;
      case "Completed":
        console.log("Anda Sudah Logout Hari Ini");
        break;
      default:
        var body = {
          iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
        };
        axios({
          method: "post",
          url: "Absensi/checkIn",
          data: body,
        })
          .then(function (res) {
            if (res.data.Data != "iduser_absensi_kosong") {
              var idabsesi = res.data.Data["IDABSENSI"];
              var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
              sessionStorage.setItem("IDABSENSI", idabsesi);
              sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
              console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  const Navigate = (key) => {
    navigate("/" + key, { replace: true });
  };
  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
      toast("Message : " + message);
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    acceptPolicy();
  }, []);

  const SelectFrontCamera = () => {
    setCameraSelected("user");
    console.log("sekarang menggunakan kamera depan");
  };
  const SelectBackCamera = () => {
    setCameraSelected("environment");
    console.log("sekarang menggunakan kamera belakang");
  };

  if (Cameraselected == "user") {
    var buttonRotateCamera = (
      <button
        variant="outline-light"
        onClick={SelectBackCamera}
        className="custombutton"
      >
        <Icon.RotateCamera />
      </button>
    );
  } else {
    var buttonRotateCamera = (
      <button
        variant="outline-light"
        onClick={SelectFrontCamera}
        className="custombutton"
      >
        <Icon.RotateCamera />
      </button>
    );
  }

  return (
    <>
    <ToastContainer
        position="bottom-center"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
      {isProcessing ? <LoadingScreen /> : null}

      <div
        id="navbar"
        className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom"
      >
        <div className="row align-items-center">
          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("Dashboard")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.HomeIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ScanQr")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.ScanQrIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ProfilSaya")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.SettingsIcon className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <Navbar className="bg-default rounded-bottom">
              <Container fluid>
                <Navbar.Brand className="text-white">
                  <a onClick={(key) => Navigate("Dashboard")}>
                    <Icon.BackIcon />
                  </a>
                  <span className="ms-3">Scan Qr Code Abbsen</span>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end text-white">
                  <Navbar.Text>
                    <Icon.ArrowsSort />
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div data-cy="card-info" className="col-12 px-0 mt-0">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div className="mt-5">
                {startScan && (
                  <>
                    <QrReader
                      facingMode={Cameraselected}
                      delay={1000}
                      onError={handleError}
                      onScan={handleScan}
                      // chooseDeviceId={()=>selected}
                      style={myStyle.sizeScan}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="text-center mt-2">
              {/* {buttonRotateCamera} */}
              <NotificationContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AppScan