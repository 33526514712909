import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, FloatingLabel, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../components/loadingScreen";
import PasswordField from "../components/passwordField";

function App() {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(true);
  const [showPass, setShowPass] = useState(true);

  const [dataModel, setDataModel] = useState([
    {
      email: "",
      password: "",
    },
  ]);
  function handleChange(e) {
    setDataModel({ ...dataModel, [e.target.name]: e.target.value });
  }

  const pageTo = () => {
    setIsProcessing(true);
    setTimeout(() => {
      setIsProcessing(false);
      navigate("/BuatAkun", { replace: true });
    }, 1300);
  };

  const login = () => {
    var config = {
      method: "post",
      url: `Login/auth`,
      headers: { "Content-Type": "multipart/form-data" },
      data: dataModel,
    };
    axios(config)
      .then(function (res) {
        let Status = res.data.status;
        let Message = res.data.Message;
        let IDUSER_ABSENSI = res.data.Record["IDUSER_ABSENSI"];
        let IDUSER = res.data.Record["IDUSER"];
        let NAMACABANG = res.data.Record["NAMACABANG"];
        let EMAILLOGIN = res.data.Record["EMAIL"];

        let NAMALENGKAP = res.data.UserAdmin["NAMADEPAN"];
        let ALAMAT = res.data.UserAdmin["ALMT"];
        let EMAIL = res.data.UserAdmin["EMAIL"];
        let HP = res.data.UserAdmin["HP"];
        let JABATAN = res.data.UserAdmin["JABATAN"];
        let IDJABATAN = res.data.UserAdmin["IDJABATAN"];

        // loged in, Redirect to dashboard
        // Create Session.
        sessionStorage.setItem("Status", Status);
        sessionStorage.setItem("Message", "Welcome in App Absensi!");
        sessionStorage.setItem("IDUSER_ABSENSI", IDUSER_ABSENSI);
        sessionStorage.setItem("IDUSER", IDUSER);
        sessionStorage.setItem("EMAIL_LOGIN", EMAILLOGIN);
        sessionStorage.setItem("EMAIL", EMAIL);
        sessionStorage.setItem("NAMALENGKAP", NAMALENGKAP);
        sessionStorage.setItem("HP", HP);
        sessionStorage.setItem("ALAMAT", ALAMAT);
        sessionStorage.setItem("NAMA_PERUSAHAAN", NAMACABANG);
        sessionStorage.setItem("IDJABATAN", IDJABATAN);
        sessionStorage.setItem("JABATAN", JABATAN);

        setIsProcessing(true);

        Status === 200
          ? setTimeout(() => {
              setIsProcessing(false);
              navigate("/Dashboard", { replace: true });
            }, 1000)
          : setTimeout(() => {
              setIsProcessing(false);
              toast.info("Message: " + Message);
            }, 1000);
      })
      .catch(function (error) {
        setTimeout(() => {
          toast.error("Message : " + error);
          console.log(error);
          setIsProcessing(false);
        }, 1000);
      });
  };

  const requestPassword = () => {
    toast.info("in development Process!", {
      toastId: "custom-id-yes",
    });
  };

  const toastInfo = () => {
    sessionStorage.setItem("Message", "You must log in first!");

    if (sessionStorage.getItem("Message") == null) {
      sessionStorage.getItem("Message", true);
    } else {
      toast.warning("Message : " + sessionStorage.getItem("Message", true), {
        toastId: "custom-id-yes",
      });
    }
  };
  const falseProcessing = () => {
    setTimeout(() => {
      setIsProcessing(false);
    }, 2500);
  };

  useEffect(() => {
    toastInfo();
    falseProcessing();
  }, []);

  return (
    <div className="container-fluid">
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {isProcessing ? <LoadingScreen /> : ""}
      <div className="row">
        <div className="col-12 px-0">
          <div className="bg-default bg-gradient rounded-bottom p-4 py-5">
            <p className="h5 text-center text-white py-5 lt-spacing">
              Aplikasi Absensi
            </p>
          </div>
        </div>

        <div
          data-cy="form-login"
          className="col-12 px-0 z-index-1 position-absolute mt-11"
        >
          <div className="bg-white bg-gradient rounded-4 shadow p-3 py-3 mx-4 mb-4">
            <p className="h6 text-center text-default py-4 mt-0 lt-spacing">
              Login Akun
            </p>
            <FloatingLabel controlId="floatingInputGrid" label="Email address">
              <Form.Control
                type="text"
                onChange={handleChange}
                name="email"
                placeholder="name@example.com"
              />
            </FloatingLabel>
            <Form.Text className="text-info">
              Login dengan email yang terdaftar.
            </Form.Text>

            <PasswordField
              dataRecord={{
                data: dataModel.password,
                onChange: handleChange,
                name: "password",
              }}
            />

            <div className="text-center mt-4 d-grid gap-2">
              <a
                onClick={requestPassword}
                className="text-decoration-none link-secondary mb-2"
              >
                Lupa password?
              </a>
              <Button
                onClick={login}
                variant="default text-white rounded-3 py-2"
                size="sm"
              >
                Masuk
              </Button>
              <a
                onClick={pageTo}
                className="text-decoration-none link-secondary mt-2"
              >
                Tidak memiliki akun?
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom">
        <div className="row align-items-center">
          <div className="col text-center bg-gray-100 text-muted">    
            <button type="button" class="btn btn-light bg-gray-100 border-0">
              <p
                class="badge badge-gray text-gray text-muted mb-0"
              >by <span href="https://kazuyamedia.com" alt="Kazuya Media Indonesia">Kazuya Media Indonesia</span></p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
