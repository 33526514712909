import React, { useEffect, useState, Component } from "react";
import {
  Button,
  Container,
  Navbar,
  Placeholder,
  ListGroup,
} from "react-bootstrap";
import CheckIconAbsensi from "../components/checkAbsensiIconsRiwayat";
import Icon from "../components/icons";

import { Icons, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LoadingScreen from "../components/loadingScreen";

////////////////////////////////////////////////////////////////
import { useNavigate } from "react-router-dom";
import logo from "../assets/icons/whusnet_icon.png";
import { ListItem, ListItemText } from "@material-ui/core";
// import { contains } from "jquery";

const myStyle = {
  height: {
    minHeight: "100vh",
  },
};

function App() {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [dataPenggunaLogin, setdataPenggunaLogin] = useState({
    IDCABANG: "",
    IDUSER_ABSENSI: "",
    IDUSER: "",
    EMAIL: "",
    NAMALENGKAP: "",
    NAMA_PERUSAHAAN: "",
    HP: "",
    ALAMAT: "",
    JABATAN: "",
  });
  const [STATUS_ABSENSI, setSTATUS_ABSENSI] = useState("");
  const [Reload, setReload] = useState("");
  const [datefrom, setdatefrom] = useState("");
  const [dateto, setdateto] = useState("");
  const [dataRiwayat, setdataRiwayat] = useState([]);
  // distance param
  const [isDistence, setisDistence] = useState([]);
  const [radius, setRadius] = useState("");
  const [error, setError] = useState(null);
  const [ShowAlert, setShowAlert] = useState(true);

  const Navigate = (key) => {
    if (key == "ScanQr") {
      if (radius == "Outside") {
        toast.warning("Message : Anda diluar jangkauan", {
          toastId: "custom-id-yes",
        });
      } else {
        navigate("/" + key, { replace: true });
      }
    } else {
      navigate("/" + key, { replace: true });
    }
  };

  const getRiwayat = () => {
    setIsSkeleton(true);
    var url = `Riwayat/riwayat`;
    var param = {
      iduser: sessionStorage.getItem("IDUSER_ABSENSI", true),
      datefrom: datefrom,
      dateto: dateto,
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        setdataRiwayat(res.data.Data);
        setTimeout(() => {
          setIsSkeleton(false);
        }, 1066);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const getDataPengguna = () => {
    let IDCABANG = sessionStorage.getItem("IDCABANG", true);
    let IDUSER_ABSENSI = sessionStorage.getItem("IDUSER_ABSENSI", true);
    let IDUSER = sessionStorage.getItem("IDUSER", true);
    let EMAIL = sessionStorage.getItem("EMAIL", true);
    let NAMALENGKAP = sessionStorage.getItem("NAMALENGKAP", true);
    let NAMA_PERUSAHAAN = sessionStorage.getItem("NAMA_PERUSAHAAN", true);
    let HP = sessionStorage.getItem("HP", true);
    let ALAMAT = sessionStorage.getItem("ALAMAT", true);
    let JABATAN = sessionStorage.getItem("JABATAN", true);
    setdataPenggunaLogin({
      ...dataPenggunaLogin,
      IDCABANG: IDCABANG,
      IDUSER_ABSENSI: IDUSER_ABSENSI,
      IDUSER: IDUSER,
      EMAIL: EMAIL,
      NAMALENGKAP: NAMALENGKAP,
      NAMA_PERUSAHAAN: NAMA_PERUSAHAAN,
      HP: HP,
      ALAMAT: ALAMAT,
      JABATAN: JABATAN,
    });
  };

  const CekDataCheckInToday = () => {
    var url = `Absensi/CekDataCheckInToday`;
    var param = {
      iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
    };
    axios
      .get(url, {
        params: param,
      })
      .then((res) => {
        if (res.data.Data != "Belum Absen Hari Ini") {
          var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
          var IDABSENSI = res.data.Data["IDABSENSI"];
          setSTATUS_ABSENSI(STATUS_ABSENSI);
          sessionStorage.setItem("IDABSENSI", IDABSENSI);
          sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
          setReload(STATUS_ABSENSI);
        } else {
          sessionStorage.setItem("STATUS_ABSENSI", "Belum_Absen");
        }
        console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const AbsenLogin = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    console.log(STATUS_ABSENSI);
    switch (STATUS_ABSENSI) {
      case "CheckIn":
        // console.log("Anda Sudah Absen Hari Ini");
        toast.warning("Message : Anda Sudah Absen Hari Ini", {
          toastId: "custom-id-yes",
        });
        break;
      case "Pending":
        // console.log("Anda Sudah Absen Hari Ini");
        var msg = "Anda Sudah Absen, Sistem sedang menelusurinya.";
        toast.warning("Message : " + msg, {
          toastId: "custom-id-yes",
        });
        break;
      case "Completed":
        // console.log("Anda Sudah Check-Out Hari Ini");
        var msg = "Anda Sudah Check-Out Hari Ini";
        toast.warning("Message : " + msg, {
          toastId: "custom-id-yes",
        });
        break;
      default:
        var body = {
          iduser_absensi: sessionStorage.getItem("IDUSER_ABSENSI", true),
          idjabatan: sessionStorage.getItem("IDJABATAN", true),
        };
        axios({
          method: "post",
          url: "Absensi/checkInPanding",
          data: body,
        })
          .then(function (res) {             
                // modul check in panding
                switch (res.data.Data) {

                  case "jabatan_tidak_terdaftar":
                    alert("jabatan belum terdaftar");
                    toast.warning("Message : Jabatan Anda Belum diizinkan system.", {
                      toastId: "custom-id-yes",
                    });
                  break;

                  case "belum_masuk_waktu_checkin":
                    alert("belum masuk waktu");
                    toast.warning("Message : Anda belum waktunya check-in. Silahkan tunggu!", {
                      toastId: "custom-id-yes",
                    });
                  break;

                  case "iduser_absensi_kosong":
                    alert("iduser kosong");
                    toast.warning("Message : Session idUser Hilang. Silahkan ulangi login!", {
                      toastId: "custom-id-yes",
                    });
                  break;
                
                  default:
                    setTimeout(() => {
                      var idabsesi = res.data.Data["IDABSENSI"];
                      var STATUS_ABSENSI = res.data.Data["STATUS_ABSENSI"];
                      setSTATUS_ABSENSI(STATUS_ABSENSI);
                      sessionStorage.setItem("IDABSENSI", idabsesi);
                      sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
                      sessionStorage.setItem("Message", "Success Absen diluar area. Menunggu Persetujuan!");
                      setReload(STATUS_ABSENSI);
                    }, 1000);
                    break;
                }
            }
          )
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  const AbsenLogOut = () => {
    var STATUS_ABSENSI = sessionStorage.getItem("STATUS_ABSENSI");
    switch (STATUS_ABSENSI) {
      case "Pending":
        var msg = "Anda Sudah Absen, Sistem sedang menelusurinya.";
        toast.warning("Message : " + msg, {
          toastId: "custom-id-yes",
        });
        break;
      case "Completed":
        var msg = "Anda Sudah Check-Out Hari Ini";
        toast.warning("Message : " + msg, {
          toastId: "custom-id-yes",
        });
        break;
      default:
        var body = {
          idabsensi: sessionStorage.getItem("IDABSENSI", true),
        };
        axios({
          method: "post",
          url: "/Absensi/checkOut",
          data: body,
        })
          .then(function (res) {
            console.log(res);
            if (res.data.Data != "idabsensi_kosong") {
              setSTATUS_ABSENSI("Completed");
              sessionStorage.setItem("STATUS_ABSENSI", "Completed");
              console.log(sessionStorage.getItem("STATUS_ABSENSI", true));
              setReload(STATUS_ABSENSI);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        break;
    }
  };

  // distance function
  const calculateDistance = () => {
    // coordinate user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var url = `Absensi/GetDataLatLongCabang`;
          var iduser_absensi = sessionStorage.getItem("IDUSER_ABSENSI", true);
          const res = axios
            .get(url, { params: { iduser_absensi } })
            .then(function (res) {
              
              // loop existing coordinate.
              let record = res.data.Data;
              record.forEach(record => {
                const centerLatitude = record["LAT_PERUSAHAAN"];
                const centerLongitude = record["LONG_PERUSAHAAN"];

                // const centerLatitude = -7.8561949; // Example latitude
                // const centerLongitude = 111.490201; // Example longitude
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                // Coordinates of the center point
                // Radius in meters
                const radiusInMeters = record["RADIUSINMETER"]; // Example radius in meters
                // Convert degrees to radians
                const deg2rad = (deg) => deg * (Math.PI / 180);
                // Calculate the distance using Haversine formula
                const distance = (lat1, lon1, lat2, lon2) => {
                  const earthRadius = 6371e3; // Earth's radius in meters
                  const dLat = deg2rad(lat2 - lat1); // cari panjang jarak A ke B
                  const dLon = deg2rad(lon2 - lon1);
                  const a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(deg2rad(lat1)) *
                    Math.cos(deg2rad(lat2)) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    return earthRadius * c;
                };
                // Calculate the distance between the center point and the input coordinates
                const dist = distance(
                  centerLatitude,
                  centerLongitude,
                  latitude,
                  longitude
                ); // output nilai jarak
                // console.log(record['RADIUSINMETER']);  

                switch (dist > radiusInMeters) {
                  case true:
                    isDistence.push("Outside")
                    break;
                
                  default:
                    isDistence.push("Inside")
                    break;
                  }
                
              });
              // console.log(isDistence)
              let statusdistane = isDistence.includes("Inside")
              if (statusdistane == true) {
                  setRadius("Inside");
              } else {
                setRadius("Outside");
              }
            })
            .catch(function (error) {
              // console.log(error);
            });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  // function acceptPolicy
  const acceptPolicy = () => {
    try {
      let status = sessionStorage.getItem("Status", true);
      let message = sessionStorage.getItem("Message", true);
      if (status != 200) {
        navigate("/", { replace: true });
      }
    } catch (err) {
      let Message = sessionStorage.getItem("Message", true);
      sessionStorage.setItem("Message", Message);
      navigate("/", { replace: true });
    }
  };

  const toastInfo = () => {
    toast.success("Message : " + sessionStorage.getItem("Message", true), {
      toastId: "custom-id-yes",
    });
  };

  const pageToRiwayat = () => {
    // setIsProcessing(true);
    setTimeout(() => {
      // setIsProcessing(false);
      navigate("/RiwayatAbsensi", { replace: true });
    }, 250);
  };

  const pageToSetting = () => {
    setIsProcessing(true);
    setTimeout(() => {
      setIsProcessing(false);
      navigate("/ProfilSaya", { replace: true });
    }, 800);
  };

  if (radius == "Outside") {
    if (STATUS_ABSENSI == "CheckIn") {
      var IconNavbar = <Icon.IndicatorOpen />;
      var TombolAbsen = (
        <Button
          onClick={AbsenLogOut}
          variant="outline-light rounded-3 py-2 mt-2"
          size="sm"
        >
          Check Out
        </Button>
      );
    } else {
      var IconNavbar = <Icon.IndicatorClose />;
      var TombolAbsen = (
        <Button
          onClick={AbsenLogin}
          variant="outline-light rounded-3 py-2 mt-2"
          size="sm"
        >
          Absen Now
        </Button>
      );
    }
  } else {
    if (STATUS_ABSENSI == "CheckIn") {
      var IconNavbar = <Icon.IndicatorOpen />;
      var TombolAbsen = "";
    } else {
      var IconNavbar = <Icon.IndicatorClose />;
      var TombolAbsen = "";
    }
  }

  // function destroy Access
  function destroyAccess() {
    setIsProcessing(true);
    setTimeout(() => {
      sessionStorage.clear();
      sessionStorage.setItem("Message", "You must log in first!");
      navigate("/", { replace: true });
    }, 1500);
  }

  useEffect(() => {
    sessionStorage.setItem("STATUS_ABSENSI", STATUS_ABSENSI);
    acceptPolicy();
    getDataPengguna();
    CekDataCheckInToday();
    getRiwayat();
    calculateDistance();
    toastInfo();
  }, [Reload]);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
      {isProcessing ? <LoadingScreen /> : null}
      <div
        id="navbar"
        className="container-fluid bg-light px-2 z-index-1 position-fixed fixed-bottom"
      >
        <div className="row align-items-center">
          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("Dashboard")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.HomeIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={(key) => Navigate("ScanQr")}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.ScanQrIcon className="text-white" />
              </a>
            </div>
          </div>

          <div className="col">
            <div className="list-group">
              <a
                onClick={pageToSetting}
                className="text-center border-0 bg-light list-group-item list-group-item-action active"
                aria-current="true"
              >
                <Icon.SettingsIcon className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-12 px-0">
            <Navbar>
              <Container fluid>
                <Navbar.Brand href="/Dashboard">
                  <img
                    alt=""
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                  />
                  <Navbar.Text className="ps-2">Aplikasi Absensi</Navbar.Text>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>{IconNavbar}</Navbar.Text>
                  <Navbar.Text label="exit" className="ps-2" onClick={destroyAccess}>
                    <Icon.powerOff />
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div className="col-12 bg-white ps-2">
            <h6 className="pt-3 ps-2 text-dark-800">
              Hello, {dataPenggunaLogin.NAMALENGKAP}. Selamat Bekerja!
            </h6>
          </div>

          <div
            data-cy="card-info"
            style={myStyle.height}
            className="col-12 px-0 mt-2"
          >
            <div className="bg-default bg-gradient rounded-top shadow-sm p-3 py-3 mx-2 mb-0">
              <div className="d-flex align-items-center bd-highlight">
                <div className="p-2 w-100 bd-highlight">
                  <div className="col-12 text-white">
                    <div className="">{dataPenggunaLogin.EMAIL}</div>
                  </div>
                  <hr className="text-white my-1" />
                  <div className="col-12">
                    <div className="text-white-50">
                    {dataPenggunaLogin.NAMALENGKAP}
                    </div>
                  </div>
                </div>
                <div className="p-2 flex-shrink-1 bd-highlight">
                  <div className="text-center">
                    <div className="mb-2 mx-4">
                      <a onClick={(key) => Navigate("ScanQr")}>
                        <Icon.QrCode />
                      </a>
                    </div>
                    {TombolAbsen}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-200 rounded-bottom px-4 py-3 mx-2 mb-2">
              <div className="row gx-1">
                <div className="col text-white">
                  <div className="text-black-50">
                    Id : {dataPenggunaLogin.IDUSER}
                  </div>
                </div>
                <div className="col align-items-end text-end">
                  <div className="text-black-50">
                    No. {dataPenggunaLogin.HP}
                  </div>
                </div>
              </div>
            </div>

            <div
              data-cy="section-riwayat-Absensi"
              className="bg-white bg-gradient px-3 mx-2 py-2"
            >
              <div className="row px-2">
                <div className="col-9 ps-0 text-dark">
                  <p className="text-dark-800 lt-spacing">Riwayat Absensi</p>
                </div>
                <div className="col-3 align-items-end text-end pe-0">
                  <a className="text-decoration-none" onClick={pageToRiwayat}>
                    Detail <Icon.ArrowRight />
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col list-group pe-0">
                  {dataRiwayat.map((item, i) => {
                    if (i + 1 <= 5) {
                      if (item.CHECKOUT_AT_HOUR != null) {
                        var HourItem = item.CHECKOUT_AT_HOUR;
                        var DateItem = item.CHECKOUT_AT_DATE;
                      } else {
                        var HourItem = item.CHECKIN_AT_HOUR;
                        var DateItem = item.CHECKIN_AT_DATE;
                      }
                      return isSkeleton ? (
                        <Placeholder
                          as={ListGroup}
                          className="my-2 p-0 rounded bg-gray-200 d-flex justify-content-between"
                          animation="wave"
                        >
                          <Placeholder as={ListItem} animation="wave">
                            <Placeholder
                              as={ListItemText}
                              xs={7}
                              className="py-2 ms-1 my-2 border-1 mb-auto"
                            />
                            <Placeholder
                              as={ListItemText}
                              xs={2}
                              className="py-2 ms-1 my-2 border-1 mb-auto"
                            />
                          </Placeholder>
                          <Placeholder as={ListItem} animation="wave">
                            <Placeholder
                              as={ListItemText}
                              xs={7}
                              className="py-2 ms-1 my-2 rounded border-1 mb-auto"
                            />
                            <Placeholder
                              as={ListItemText}
                              xs={5}
                              className="py-2 ms-1 my-2 rounded border-1 mb-auto bg-gray-100"
                            />
                            <Placeholder
                              as={ListItemText}
                              xs={1}
                              className="p-3 ms-1 my-2 rounded-circle border-1 mt-auto"
                            />
                          </Placeholder>
                        </Placeholder>
                      ) : (
                        <div className="card border-0 bg-gray-200 shadow-sm list-group-item-action active mb-3 px-1">
                          <div className="card-body">
                            <div className="row gx-3 ">
                              <div className="col-9 ps-0 text-dark-700 text-start d-flex align-items-center">
                                <h6 className="card-title text-default fw-600">
                                  {item.STATUS_ABSENSI}
                                </h6>
                              </div>
                              <div className="col-3 align-items-end text-end pe-0">
                                <h6 className="card-title text-dark-700">
                                  {HourItem}
                                </h6>
                              </div>
                            </div>
                            <div className="row gx-3">
                              <div className="col-9 ps-0 text-start d-flex align-items-center">
                                <p className="text-dark-700 lt-spacing mb-0">
                                  {DateItem}
                                </p>
                              </div>
                              <div className="col-3 align-items-end text-end pe-0">
                                <CheckIconAbsensi
                                  dataRecord={{ status: item.STATUS_ABSENSI }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return "empty";
                    }
                  })}
                  {/* <div className="card border-0 shadow-sm list-group-item-action active mb-2">
                    <div className="card-body">
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <h6 className="card-title text-default fw-600">Checkin</h6>
                        </div>
                        <div className="col-3 align-items-end text-end">
                          <h6 className="card-title">07:21:11</h6>
                        </div>
                      </div>
                      <div className="row gx-3">
                        <div className="col-9 ps-0 text-dark text-start d-flex align-items-center">
                          <p className="text-dark lt-spacing mb-0">Selasa,10 sep 2022</p>
                        </div>
                        <div className="col-3 align-items-end text-end pe-0">
                          <Icon.checkIcon className="text-red" />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
